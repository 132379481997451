export default `query($id: JSON!, $reqs: [String!], $page: Int!, $limit: Int!) {
    Friends: Users(
        where: { 
            AND: [
                { friends: { in: [$id] } },
                { active: { equals: true } },
                { OR: [
                    { privacy: { not_in: [private] } }, 
                    { AND: [
                        { privacy: { equals: private } },
                        { createdBy: { value: { in: [$id] } } }
                    ]}
                ]}
            ]
        }, 
        limit: $limit, 
        page: $page
    ) {
        docs {
            id
            fname
            lname
            mackertag
            image {
                url
                sizes {
                    thumbnail {
                        url
                    }
                }
            }
        }
        totalDocs
        totalPages
        page
    }
    Requests: Users(
        where: {
            id: { in: $reqs },
        }
    ) {
        docs {
            id
            fname
            lname
            mackertag
            image {
                url
                sizes {
                    thumbnail {
                        url
                    }
                }
            }
        }
    }
}`;
