export default `query($today: DateTime!, $teams: [JSON!]) {
    Tournaments (where: { teams: { in: $teams }, start: { greater_than: $today } }) {
    	docs {
        id
        title
        start
        end
        slug
        host {
          slug
        }
        teams {
          id
          name
        }
        image {
          url
          color
          sizes {
            blur {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      totalDocs
      page
      totalPages
    }
    PastTournaments: Tournaments (where: { teams: { in: $teams }, start: { less_than: $today } }) {
    	docs {
        id
        title
        start
        end
        slug
        host {
          slug
        }
        image {
          url
          color
          sizes {
            blur {
              url
            }
            thumbnail {
              url
            }
          }
        }
      }
      totalDocs
      page
      totalPages
    }
  }`;